<template>
  <dash-page-new
      :title="$t('Blocks')"
      :side-navigation="templateId ? avalonAdminTemplateNavigationItems : null"
      :navigation-return-route="localeLink('avalon_editor/templates/' + this.templateId)"
  >

    <template #header_action>
      <v-btn @click="openNewPage" :color="wsATTENTION" dark class="noCaps">
        <v-icon>mdi-plus</v-icon>
        {{ $t('CreateNew') }}
      </v-btn>
    </template>

    <ws-data-table
        class="mt-5"
        :items="items"
        :headers="headers"
        :row-action="selectBlock"
    >
      <template #item.name="{item}">
        <router-link class="black--text linkHover noUnderline" :to="openPageRoute(item)">
          <h5>{{ item.name }}</h5>
        </router-link>

      </template>
      <template #item.uploads="{item}">

        <div class="d-flex align-center">
          <v-icon class="mr-2" :color="wsACCENT"> mdi-download </v-icon>
          <h5> {{ item.uploads }}</h5>
        </div>

      </template>
    </ws-data-table>

    <template #dialog>
      <ws-dialog
          v-model="displayPageDialog"
          :title="$t('AddNewTemplate')"
          @save="addPage"
      >

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

        <ws-switch
            v-model="entityData.is_footer"
            :label="$t('IsFooter')"
            :placeholder="$t('IsPageFooter')"
        />

        <ws-switch
            v-model="entityData.is_published"
            :label="$t('IsPublished')"
            :placeholder="$t('IsTemplatePublished')"
        />

      </ws-dialog>
    </template>

  </dash-page-new>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "adminPages",
  props : {
    templateId : {
      type : String
    }
  },
  data() {
    return {
      items : [],
      categories : [],
      entityData : {},
      displayPageDialog : false
    }
  },
  computed : {
    headers() {
      return [
        { text : 'Name' , value : 'name' },
        { text : 'Uploads' , value : 'uploads' },
        { text : 'Category' , value : 'category_name' },
      ]
    }
  },
  methods : {
    ...mapActions('avalon' , [
      'GET_ADMIN_TEMPLATE_PAGES'
    ]),
    ...mapActions('avalonPage' , [
      'ADD_PAGE'
    ]),

    async addPage() {

      let data = JSON.parse(JSON.stringify(this.entityData))
      if ( this.templateId ) {
        data.template_id = this.templateId
      }
      data.is_template = true

      let result = await this.ADD_PAGE(data)
      if ( !result ) {
        return
      }
      this.blocks.unshift(result)
      this.displayBlockDialog = false

    },

    openNewPage() {
      this.entityData = {}
      this.displayPageDialog = true
    },

    selectBlock(item) {
      this.$router.push(this.openPageRoute(item))
    },
    openPageRoute(item) {
      return !this.templateId
          ? this.localeLink('avalon_editor/pages/' + item.id )
          : this.localeLink(`avalon_editor/templates/${this.templateId}/pages/` + item.id )
    },
  },
  async mounted() {
    let result = await this.GET_ADMIN_TEMPLATE_PAGES(this.templateId)
    if ( !result ) {
      return
    }
    this.items = result.pages
    this.categories = result.categories
  }
}
</script>

<style scoped>

</style>